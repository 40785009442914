/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

const Layout = ({ children }) => {
  return (
    <>
      <main className="font-primary text-lg text-black">
        <div className="container mx-auto mt-4 lg:mt-8 text-center">
          <h1 className="font-medium text-5xl uppercase">
            <a href="/">Hangframe</a>
          </h1>
          <span>
            The hangboard designed for the modern climber
          </span>
        </div>
        {children}
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
